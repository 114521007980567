<template>
    <b-container v-if="!isLoggedIn">
        <h4 class="notAllowed text-center" v-if="!isLoggedIn">No puedes ver nuestros productos si no estas  registrado. Para registrarte llama al 953741829 y hazte cliente.</h4>
    </b-container>
    <b-container v-else>
        <b-row class="my-5 product-div" v-for="(model, index) in product" :key="index">
            <b-col class="xs-hidden sm-hidden p-0">
                <ProductZoomer v-if="id_color === ''"  :base-images="images" :base-zoomer-options="zoomerOptions"/>
                <ProductZoomer v-else width="500" :base-images="colorImages" :base-zoomer-options="colorZoomerOptions"/>
            </b-col>
            <b-col class="text-product">
                <div v-if="model.ruta2 === null"  class="md-hidden lg-hidden xl-hidden">
                    <b-img class="mx-auto product-img" center thumbnail fluid :src="'https://ctworker.com/images/normal_size/' + model.modelo + '.jpg'"></b-img>
                </div>
                <div v-else class="md-hidden lg-hidden xl-hidden">
                    <agile>
                        <div class="slide hoverHand h-100" v-for="n in 2" :key="n">
                            <div  v-if="n === 1">
                                <b-img class="mx-auto product-img" center thumbnail fluid :src="'https://ctworker.com/images/normal_size/' + model.modelo + '.jpg'"></b-img>
                            </div>
                            <div  v-else-if="n === 2">
                                <b-img class="mx-auto product-img" center thumbnail fluid :src="'https://ctworker.com/images/normal_size/' + model.modelo + '_back.jpg'"></b-img>
                            </div>
                        </div>
                        <template slot="prevButton"><i class="fas fa-chevron-left"></i></template>
                        <template slot="nextButton"><i class="fas fa-chevron-right"></i></template>
                    </agile>
                </div>
                <h3 class="mx-auto text-center pr-width-title px-0 mt-md-0 mt-lg-0 formTitle">Modelo {{ model.modelo }}</h3>
                <div class="formTitle pr-width-title mx-auto mt-2 text-center">
                    <h6>{{ model.descripcion }}</h6>
                    <h6 v-if="model.tallas === 'Única'">Talla {{ model.tallas }}</h6>
                    <h6 v-else>Tallas: {{ ' ' + model.tallas }}</h6>
                    <h6 class="bg-info w-50 mx-auto" style="color:white" v-if="model.new === 1" id="newSeason">Nueva temporada</h6>
                    <h6 class="text-info" v-if="model.new === 1"><hr>En esta temporada los pedidos serán bajo fabricación. Esto quiere decir que el modelo que elija se fabricará dependiendo de las cantidades que se encarguen<hr></h6>
                    <h6 class="pt-2" v-if="model.precio_rebaja > 0 && model.new === 0">Precio anterior <del class="text-danger">{{ model.precio }} €</del></h6>
                    <h6 class="pt-2" v-if="model.precio_rebaja > 0 && model.new === 0">Precio {{ model.precio_rebaja }} €</h6>
                    <h6 class="pt-2" v-else>Precio {{ model.precio }} €</h6>
                </div>
                <div class="mt-5" v-if="(model.temporada == 'Verano' && model.año == 20)">
                    <h6 class="text-danger text-center pr-width-title"><hr>Los pedidos de esta temporada estarán disponibles más adelante</h6>
                </div>
                <div v-else class="input-field mx-auto pr-width-title mt-2">
                    <b-form @submit.prevent="addToBasket(id_color, talla, model.precio, model.precio_rebaja, model.temporada, model.familia, model.año, model.tipo)">
                        <select v-model="id_color" name="color" id="color" class="form-control select h-25" tabindex="12" required>
                            <b-form-select-option value=""  disabled>-- Color --</b-form-select-option>
                            <b-form-select-option v-for="(id_color, colorIndex) in productQuantity" :key="colorIndex" :value="id_color.id_color + id_color.color">{{ id_color.color }}</b-form-select-option>
                        </select>
                        <div v-if="model.tallas.indexOf('meses')!= -1 && id_color != ''">
                            <select :talla="talla" v-model="talla"  name="talla" id="talla" class="form-control select h-25 mt-3" tabindex="12" required>
                                <b-form-select-option value="" disabled>-- Talla --</b-form-select-option>
                                <b-form-select-option v-for="(size, tallasIndex) in sizes" :key="tallasIndex" :value="size">{{ size }}<span v-if="size === 1"> mes</span><span v-else> meses</span></b-form-select-option>
                            </select>
                        </div>
                        <div  v-else-if="model.tallas.indexOf('años')!= -1 && id_color != ''">
                            <select :talla="talla" v-model="talla"  name="talla" id="talla" class="form-control select h-25 mt-3" tabindex="12" required>
                                <b-form-select-option value="" disabled>-- Talla --</b-form-select-option>
                                <b-form-select-option v-for="(size, tallasIndex) in sizes" :key="tallasIndex" :value="size" >{{ size }} <span v-if="size === 1"> año</span><span v-else> años</span></b-form-select-option>
                            </select>
                        </div>
                        <div v-else-if="id_color == ''">
                            <select :talla="talla" v-model="talla"  name="talla" id="talla" class="form-control select h-25 mt-3" tabindex="12" required>
                                <b-form-select-option value="" disabled>-- Talla --</b-form-select-option>
                                <b-form-select-option value="" disabled>Elige un color</b-form-select-option>
                            </select>
                        </div>
                        <select :talla="talla" v-model="talla" v-else-if="model.tallas.indexOf('Única')!= -1" name="talla" id="talla" class="form-control select h-25 mt-3" tabindex="12" required>
                            <b-form-select-option value="" disabled>-- Talla --</b-form-select-option>
                            <b-form-select-option value="unica">Talla Única</b-form-select-option>
                        </select>
                        <b-form-group id="input-group-2" class="form-control select h-25 mt-3">
                            <b-form-input id="cantidad" class="form-control select ml-2 h-25 border-0" v-model="quantity" required  placeholder="--Cantidad--" type="number" min="1"></b-form-input>
                        </b-form-group>
                        <span>{{ quantities() }}</span>
                        <span :class="'quantities text-right mt-5 ml-1 text-' + textColor">{{ quantityInfo }}</span>
                        <b-alert variant="success" class="alert" :show="dismissCountDown" @dismissed="dismissCountDown = 0">Producto añadido a su cesta</b-alert>
                        <b-alert variant="warning" class="alert" :show="dismissCountDown3"  @dismissed="dismissCountDown3 = 0">Añada este producto a un pedido con productos sólo de nueva temporada</b-alert>
                        <b-alert variant="warning" class="alert" :show="dismissCountDown4"  @dismissed="dismissCountDown4 = 0">Tiene productos de nueva temporada en su cesta. Añada este producto a un pedido con productos que no sean de nueva temporada</b-alert>
                        <b-button :disabled="isDisabled" type="submit"  class="mt-4 mx-auto d-block btn-form" variant="primary">Añadir a la cesta</b-button>
                    </b-form>
                    <b-alert variant="danger" class="alert" :show="dismissCountDown2" @dismissed="dismissCountDown2 = 0">Ha habido un error! Por favor recargue la página</b-alert>
                </div>
            </b-col>
        </b-row>
        <router-link class="link text-center py-5" :to="{ name: 'Family',  params:{ season: this.$route.params.season, family: this.$route.params.family }}">Volver a {{ family }} </router-link>
    </b-container>
</template>
<script>
export default {
    name: 'Product',
    props:{
        fixNumber: Function
    },
    data(){
        return{
            n: 0,
            inCart: [],
            dismissSecs: 2,
            dismissSecs2: 8,
            dismissCountDown: 0,
            dismissCountDown2: 0,
            dismissCountDown3: 0,
            dismissCountDown4: 0,
            isLoggedIn: localStorage.getItem('tbStore.jwt') && localStorage.getItem('tbStore.user') != null,
            quantity: '',
            id_color: '',
            talla: '',
            family: this.$route.params.family.replace('_', ' '),
            textColor:'',
            quantityInfo: '',
            modelo: this.$route.params.product,
            productQuantity: [],
            product : [],
            isDisabled: false,
            colorImages: {
                'thumbs': [
                    {
                        'id': 1,
                        'url': 'https://ctworker.com/images/thumbs/' + this.$route.params.product + '_C' + this.id_color + '.jpg'
                    },
                    {
                        'id': 2,
                        'url': 'https://ctworker.com/images/thumbs/' + this.$route.params.product + '_C' + this.id_color + '_back.jpg'
                    },
                ],
                'normal_size': [
                    {
                        'id': 1,
                        'url': 'https://ctworker.com/images/normal_size/' + this.$route.params.product + '_C' + this.id_color + '.jpg'
                    },
                    {
                        'id': 2,
                        'url': 'https://ctworker.com/images/normal_size/'  + this.$route.params.product + '_C' + this.id_color + '_back.jpg'
                    },
                ],
                'large_size': [
                    {
                        'id': 1,
                        'url': 'https://ctworker.com/images/large_size/' + this.$route.params.product + '_C' + this.id_color + '.jpg'

                    },
                    {
                        'id': 2,
                        'url': 'https://ctworker.com/images/large_size/' + this.$route.params.product + '_C' + this.id_color + '_back.jpg'
                    },
                ]
            },

            images: {
                'thumbs': [
                    {
                        'id': 1,
                        'url': 'https://ctworker.com/images/thumbs/' + this.$route.params.product + '.jpg'
                    },
                    {
                        'id': 2,
                        'url': 'https://ctworker.com/images/thumbs/' + this.$route.params.product + '_back.jpg'
                    },
                ],
                'normal_size': [
                    {
                        'id': 1,
                        'url': 'https://ctworker.com/images/normal_size/' + this.$route.params.product + '.jpg'
                    },
                    {
                        'id': 2,
                        'url': 'https://ctworker.com/images/normal_size/'  + this.$route.params.product + '_back.jpg'
                    },
                ],
                'large_size': [
                    {
                        'id': 1,
                        'url': 'https://ctworker.com/images/large_size/' + this.$route.params.product + '.jpg'

                    },
                    {
                        'id': 2,
                        'url': 'https://ctworker.com/images/large_size/' + this.$route.params.product + '_back.jpg'
                    },
                ]
            },

            colorZoomerOptions: {
                zoomFactor:3, // scale for zoomer
                pane:'pane', // three type of pane ['pane', 'container-round', 'container']
                hoverDelay: 300, // how long after the zoomer take effect
                zoomer_pane_position:"right",
                namespace: "zoomer", // add a namespace for zoomer component, useful when on page have mutiple zoomer
                move_by_click: true, // move image by click thumb image or by mouseover
                scroll_items: 2,
                choosed_thumb_border_color: "#b3d7dd", // choosed thumb border color
                scroller_position: "",

            },

            zoomerOptions: {
                zoomFactor: 3, // scale for zoomer
                pane: 'pane', // three type of pane ['pane', 'container-round', 'container']
                hoverDelay: 300, // how long after the zoomer take effect
                zoomer_pane_position: "right",
                namespace: "zoomer", // add a namespace for zoomer component, useful when on page have mutiple zoomer
                move_by_click: true, // move image by click thumb image or by mouseover
                scroll_items: 2,
                choosed_thumb_border_color: "#b3d7dd", // choosed thumb border color

            }
        }
    },

    created() {
        this.$root.$emit('loader', true)
        this.axios.get('getProductInfo/' + this.$route.params.product + '/tony').then(response => {
            if (response.status === 200) {
                this.product = response.data
                this.$root.$emit('loader', false)
            }

            if (this.product[0].ruta2 === null) {
                this.zoomerOptions.scroll_items = 0
            }
        })
        this.axios.get('getProductSizes/' + this.$route.params.product).then(response =>(this.productQuantity = response.data))
        this.axios.get('getItemsFromCartByUser/' + this.$store.getters.username).then(response => (this.inCart = response.data))

    },

    computed:{
        sizes() {
            let sizes = this.product[0].tallas
            sizes = sizes.replace('y', ',')
            sizes = sizes.replace('meses', '')
            sizes = sizes.split(',')
            sizes = sizes.map(x => {
                return parseInt(x, 10);
            })
            if (this.product[0].new !== 1) {
                var arr = []
                var color = this.productQuantity.find(x => x.id_color + x.color === this.id_color)
                for (var j = 0; j < sizes.length; j++) {
                    if (color['talla_' + sizes[j]]) {
                        arr.push(sizes[j])
                    }
                }
                return arr
            }
            return sizes
        },
    },

    methods: {
        setInputsBlank() {
            this.dismissCountDown = this.dismissSecs // mensaje de añadido a la cesta
            this.talla = ""
            this.id_color=""
            this.quantity=""
        },

        createItemForBasket(id_color, talla, precio, precio_rebaja, temporada, familia, año, description, sizes) {
            let precio_final = precio_rebaja > 0 ? precio_rebaja : precio
            const  item = {
                modelo: this.modelo,
                id_color: id_color.slice(0,2),
                color: id_color.slice(2),
                talla: talla,
                cantidad: parseInt(this.quantity),
                id: this.modelo + id_color.trim() + talla,
                precio: parseFloat(precio_final),
                season: temporada,
                family: familia,
                year: año,
                new:this.product[0].new,
                description: description,
                sizes:sizes
            }
            return item
        },

        addToBasket(id_color, talla, precio, precio_rebaja, temporada, familia, año, description) {
            let sizes = 0
            let matchSales = this.inCart.find(item => item.new === 0)
            let matchNew = this.inCart.find(item => item.new === 1)

            if (!localStorage) {
                return alert('No puede realizar pedidos con este navegador. Utilice Chrome, Opera o Mozilla')
            }

            if (this.product[0].new === 1 && matchSales) {
                return this.dismissCountDown3 = this.dismissSecs2
            }

            if (this.product[0].new === 0 && matchNew) {
                return this.dismissCountDown4 = this.dismissSecs2
            }

            if (this.product[0].tallas.indexOf('años') != -1) {
                sizes = 1
            }

            let user = this.$store.getters.username
            let item = this.createItemForBasket(id_color, talla, precio, precio_rebaja, temporada, familia, año, description, sizes)

            this.axios.post('storeOrUpdateItemInCart', { user, item })
                .catch(error => {
                    if(error.response.status){
                        this.dismissCountDown2 = this.dismissSecs
                    }
                })

            this.$store.dispatch('addToCart', parseInt(this.quantity))
            this.setInputsBlank()

        },

        quantities() {
            if (this.talla === 0 && this.product[0].new === 0 || this.talla && this.product[0].new === 0) {
                let quantity = parseInt(this.quantity)
                let idInCart = this.$store.getters.inCartItems.find((x)=>x.id === this.modelo + this.id_color.trim() + this.talla)
                let stockQuantityPerSize = 0

                if (idInCart) {
                    quantity = idInCart['cantidad'] + quantity
                }

                if (this.productQuantity.length > 0) {
                    stockQuantityPerSize = this.productQuantity.find(x=> x.id_color + x.color === this.id_color)['talla_' + this.talla]
                }

                let remainQuantity =  stockQuantityPerSize - quantity

                if (remainQuantity < 0) {
                    this.isDisabled = true
                    this.quantityInfo = 'No disponemos de la cantidad que ha elegido'
                    this.textColor = 'danger'
                }

                if (remainQuantity > 0) {
                    this.isDisabled = false
                    this.quantityInfo = 'Quedan ' + remainQuantity + ' unidades de este modelo'
                    this.textColor = 'info'
                }

                if (remainQuantity === 0) {
                    this.isDisabled = false
                    this.quantityInfo = 'Son las ultimas ' + this.quantity + ' unidades'
                    this.textColor = 'danger'
                }
            }
        }
    },

    mounted() {
        document.title = 'Tony Bambino: modelo ' + this.$route.params.product

    }
}
</script>



